<template>
    <div class="modal" v-if="modelValue" :class="{active : modelValue}" @click.self="closeModel()">
        <div class="modal_container">
            <div class="modal_header border-bottom">
                <h1>Birthday List</h1>
                <button class="close_btn left_out" @click="closeModel"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="result_wpr new small m-0">
                    <div class="actions">
                        <ul>
                            <li class="optionDrops sort_list" @click="togglePerPageFilter"  v-click-outside="closePageFilter">
                               Show {{ params.per_page }}<i class="fas fa-angle-down"></i>
                                <page-filter v-model="params.per_page" :is-dropdown="true" :type="2" ref="birthday-per-page-filter" />
                            </li>
                            <li class="optionDrops search_area ml-auto active">
                                <input type="text" class="p-0" placeholder="Search" @input="isTyping = true" v-model.trim="params.search" />
                                <button class="search_btn">
                                    <i class="fas fa-search"></i>
                                </button>
                            </li>
                            <!-- <li class="list_info">
                                {{ birthdayList.from ? birthdayList.from : 0 }} - {{ birthdayList.to ? birthdayList.to : 0 }} of <span>{{ birthdayList.total ? birthdayList.total : 0 }}</span>
                            </li> -->
                        </ul>
                    </div>
                    <div v-if="loader"><line-loader /></div>
                    <div class="scroll_table">
                        <table class="standard show_header">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Date of Birth</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody  v-if="birthdayList.total">
                                <tr v-for="(contact, c) in birthdayList.data" :key="c">
                                    <td>
                                        <div class="user_wpr">
                                            <h4>{{ contact.name }}</h4>
                                        </div>
                                    </td>
                                    <td>{{ contact.birthdate ? moment(contact.birthdate ).format('ll') : ''}}</td>
                                    <td>
                                        <label :for="`title-${c}`" class="capsule_btn">
                                            <input type="checkbox" :id="`title-${c}`" :checked="contact.celebrate_birthday == 1" @change=" toggleBirthdayCelebrate($event, contact)" hidden>
                                            <div><span></span></div>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td colspan="3" class="text_center">
                                        <div class="empty_box">
                                            <img src="@/assets/images/empty_state.svg">
                                            <h4>No Records Found</h4>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table_footer">
                    <ul>
                        <li>{{ birthdayList.from ? birthdayList.from : 0 }} - {{ birthdayList.to ? birthdayList.to : 0 }} of {{ birthdayList.total ? birthdayList.total : 0 }}</li>
                    </ul>
                </div>
                <div class="pagination" v-show="birthdayList.total">
                    <pagination v-model="params.page" :range-size="0" :pages="birthdayList.last_page" @update:modelValue="handlePagination" />
                </div>
            </div>
            <div class="modal_footer">
                <button class="btn cancel_btn" @click="closeModel">Close</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { defineAsyncComponent } from 'vue'
    import { mapState, mapActions} from 'vuex'

    import moment from 'moment'

    export default {
        name: 'Birthday List',

        data() {
            return {
                params: {
                    page: 1,
                    per_page: 5,
                    search: ''
                },
                isTyping: false,
                selectedContact: {},
                moment
            }
        },

        props: {
            modelValue: Boolean,
        },

        watch: {
            'params.per_page' () {
                const vm = this;

                vm.params.page = 1;
                vm.getUpcomingBirthdays(vm.params);
            },

            'params.search' () {
                const vm = this;

                setTimeout(() => {
                    vm.isTyping = false;
                }, 1500);
            },

            isTyping (val) {
                const vm = this;

                if (!val) {
                    if (vm.params.search != null) {
                        if (vm.params.search.length >= 2) {
                            vm.params.page = 1;
                            vm.getUpcomingBirthdays(vm.params);
                        }
                        if (vm.params.search.length === 0) {
                            vm.params.page = 1;
                            vm.getUpcomingBirthdays(vm.params);
                        }
                    }
                }
            },

            modelValue (value) {
                if (value) {
                    const vm = this;
                    document.body.classList.add('modal-open');

                    setTimeout(function () {
                        vm.getUpcomingBirthdays(vm.params);
                    }, 1000);
                }else{
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            birthdayList: state => state.commonModule.birthdayList,
            loader: state => state.commonModule.loader,
        }),

        methods: {
            ...mapActions({
                getUpcomingBirthdays: 'commonModule/getUpcomingBirthdays',
                updateCelebrateBirthday: 'commonModule/updateCelebrateBirthday',
            }),

            closeModel () {
                const vm = this;

                vm.$emit('update:modelValue', false)
            },

            togglePerPageFilter () {
                const vm = this;
                const filter = vm.$refs['birthday-per-page-filter'];

                if (filter) {
                    filter.dropdown = !filter.dropdown;
                }
            },

            closePageFilter () {
                const vm = this;
                const filter = vm.$refs['birthday-per-page-filter'];

                if (filter) {
                    filter.dropdown = false;
                }
            },

            handlePagination (page) {
              const vm = this;

              vm.params.page = page;
              vm.getUpcomingBirthdays(vm.params);
            },

            toggleBirthdayCelebrate(e, contact) {
                const vm = this;

                const form = {
                    status: e.target.checked ? 1 : 0,
                    contact_id: contact.id,
                    contact_name: contact.name
                };

                vm.updateCelebrateBirthday(form).then((result) => {
                    vm.getUpcomingBirthdays(vm.params);
                });
            }
        }
    }
</script>

<style scoped>
    .result_wpr table.standard td:first-child, .result_wpr table.standard th:first-child{
        width: auto;
        text-align: left;
    }
    .result_wpr.small table.standard td.action{
        width: 30px;
    }
    .result_wpr.small table.standard td.action i{
        width: auto;
        font-size: 13px;
    }
    .result_wpr.small table.standard td .drp_wrapper, .tempcard ul li .drp_wrapper{
        display: none;
    }
    .result_wpr.small table.standard td .drp_wrapper.active, .tempcard ul li .drp_wrapper.active{
        display: block;
    }
    .result_wpr table.standard td.text_center {
        width: auto;
        text-align: center !important;
    }
    @media(max-width: 767px){
        .result_wpr.small .actions{
            min-width: 100% !important;
        }
        .result_wpr.new.small .actions > ul li.search_area{
            width: 190px;
        }
        .result_wpr.new.small .actions > ul li.search_area input[type=text]{
            width: 100%;
            padding: 0 15px 0 0;
        }
    }
</style>
